<template>
  <b-row>

    <b-col
      md="12"
    >
      <b-card
        title="Liste des offres"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="offres"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <span
              @click="$router.push({name:'espace-admin.details',params:{code:data.item.code}})"
            >
              <feather-icon
                icon="EyeIcon"
                badge-classes="bg-primary"
              />
              <span class="align-middle text-primary ml-50" />
            </span>

            <b-dropdown
              variant="link"
              no-caret
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-b-modal.validation
                @click.prevent="getOffreCode(data.item.code, 'Validée' )"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  class="text-success"
                />
                <span class="align-middle text-success ml-50"> Valider</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal.rejet
                @click.prevent="getOffreCode(data.item.code, 'Rejetée' )"
              >

                <feather-icon
                  icon="XSquareIcon"
                  class="text-danger"
                />
                <span class="align-middle text-danger ml-50"> Rejeter</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="offres.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>

    <!-- form modal annulation-->
    <b-modal
      id="rejet"
      ref="my-modal"
      cancel-title="Non"
      title="Rejet d'offre"
      ok-title="Oui"
      modal-class="modal-danger"
      ok-variant="danger"
      button-size="sm"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      @ok="handleOk"
    >
      <validation-observer
        ref="ValidationRules"
      >
        <form @submit.prevent="handleSubmitModal">
          <h6>Etes-vous sûr de vouloir rejeter cette offre?</h6>
          <b-row>
            <b-col sm="12">
              <label
                for="textarea-small"
                class="text-nowrap"
              >Commentaire:</label>
            </b-col>
            <b-col
              sm="12"
              class="mb-1"
            >

              <validation-provider
                #default="{ errors }"
                name="commentaire"
                rules="required"
              >
                <b-form-textarea
                  id="textarea-small"
                  v-model="dataValidate.rejet_comment"
                  size="sm"
                  placeholder="Commentaire"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

          </b-row>

        </form>
      </validation-observer>
    </b-modal>

    <!-- form modal validation -->
    <b-modal
      id="validation"
      ref="my-modal"
      cancel-title="Non"
      ok-title="Oui"
      title="Validation d'offre"
      modal-class="modal-success"
      ok-variant="success"
      button-size="sm"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      @ok="handleValidate"
    >
      <form @submit.prevent="handleSubmitModalValidate">
        <h6>Etes-vous sûr de vouloir valider cette offre?</h6>

      </form>
    </b-modal>

  </b-row>

</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BFormInput, BSpinner, BFormTextarea,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useOffres from '@/services/affreteur/offresService'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormTextarea,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const {
      getAllOffersByStateNotValidated, validateOffre, offres, loader,
    } = useOffres()
    const offreCode = ref('')
    const dataValidate = reactive({ is_accepted: '', rejet_comment: '' })
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'code', label: 'CODE DE L\'OFFRE', sortable: true },
      { key: 'created_at', label: 'DATE DE CREATION', sortable: true },
      { key: 'ville_depart.name', label: 'Ville départ', sortable: true },
      { key: 'date_prev_depart', label: 'DATE DE DÉPART', sortable: true },
      { key: 'actions' },
    ]
    const form = reactive({
      libelle: '',
      description: '',
    })
    /* eslint-disable global-require */
    const items = offres

    onMounted(() => {
      getAllOffersByStateNotValidated()
      // Set the initial number of items
      totalRows.value = items.length
    })

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    const acceptOffer = async (code, data) => {
      await validateOffre(code, data)
      dataValidate.rejet_comment = ''
    }
    const getOffreCode = (code, stateValidity) => {
      offreCode.value = code
      dataValidate.is_accepted = stateValidity
    }
    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      items,
      onFiltered,
      form,
      formatter,
      loader,
      offres,
      getOffreCode,
      offreCode,
      acceptOffer,
      dataValidate,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {

    validationContactForm() {
      return new Promise((resolve, reject) => {
        this.$refs.ValidationRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    handleOk(bvModalEvt) {
    // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.ValidationRules.validate().then(success => {
        if (success) {
          // Push the name to submitted names
          this.acceptOffer(this.offreCode, this.dataValidate)
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#toggle-btn')
          })
        }
      })
    },
    handleValidate(bvModalEvt) {
    // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModalValidate()
    },
    handleSubmitModalValidate() {
      // Exit when the form isn't valid
      this.acceptOffer(this.offreCode, this.dataValidate)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
